<template>

  <SplashScreen/>
  <FeatureBoxes/>

  <Homebigadvimg/>



  <div class="container">
    <div class="section-title">
      <span>be prepared</span>
      <h2>Available trainings</h2>
    </div>
  </div>

  <Subjects/>


</template>




<script>
// @ is an alias to /src
import Subjects from '@/views/content/Subjects.vue'
import SplashScreen from '@/views/content/splashscreen.vue'
import FeatureBoxes from '@/views/content/featureboxes.vue'
import Homebigadvimg from '@/views/content/homebigadvimg.vue'



export default {
  name: 'Home',
  components: {
    Subjects,
    SplashScreen,
    FeatureBoxes,
    Homebigadvimg,
  },

  created() {
    window.scrollTo(0,0); // scroll to top
  },

  methods: {

  }
}
</script>
