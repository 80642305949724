<template>

  <div class="pulse-loader-bar">
    <pulse-loader  color="#E21F36" size="10px"></pulse-loader>
  </div>


</template>

<script>

import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  components: {
    PulseLoader
  },
}

</script>
