
<template>



  <div class="newsletter-banner-maxrow">
    <div class="container">
      <div class="newsletter-banner">
        <NewsletterWide v-if="!showNewsletterThankYouBanner"/>
        <newsletterWideSubSent v-if="showNewsletterThankYouBanner"/>
      </div>
    </div>
  </div>

</template>



<script>
import NewsletterWide from '@/views/content/splash/newsletterWide.vue';
import newsletterWideSubSent from '@/views/content/splash/newsletterWideSubSent.vue';

export default {
  components: {
    NewsletterWide,
    newsletterWideSubSent
  },
  data() {
    return {
      showNewsletterBanner: false,
      showNewsletterThankYouBanner: false
    }
  },

  mounted(){
    this.emitter.on("newsletterSubscribed", () => {
      console.log("event on: newsletterSubscribed - banner");
      this.showNewsletterThankYouBanner = true;
    });

  }
}

</script>
