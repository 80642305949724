<template>

  <!-- Start Header Area -->
  <header class="header-area p-relative">

    <!-- Start Navbar Area -->
    <div class="navbar-area navbar-area-two">
      <div class="mobile-nav">
        <div class="container">
          <router-link to="/" class="logo">
            <img alt="bitsandbytes">
          </router-link>

        </div>
      </div>

      <div class="main-nav">
        <div class="container">
          <nav class="navbar navbar-expand-md">
            <router-link to="/" class="logo">
              <img alt="bitsandbytes">
            </router-link>

            <div class="collapse navbar-collapse mean-menu">

              <ul class="navbar-nav m-auto">

                <li class="nav-item">
                  <router-link to="/" class="nav-link">Home</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/faq" class="nav-link">FAQ</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/login" class="nav-link">Account</router-link>
                </li>

              </ul>

              <div class="others-option">
                <div class="get-quote">
                  <router-link v-if="userSignedOut()" to="/login" class="default-btn">Sign in</router-link>
                  <router-link v-if="userSignedIn()" to="/login" class="default-btn signedIn-btn">Signed in</router-link>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>

      <div class="others-option-for-responsive">
        <div class="container">
          <div class="dot-menu">
            <div class="inner">
              <div class="circle circle-one"></div>
              <div class="circle circle-two"></div>
              <div class="circle circle-three"></div>
            </div>
          </div>

          <div class="container">
            <div class="option-inner">
              <div class="others-option justify-content-center d-flex align-items-center">
                <div class="get-quote">
                  <router-link v-if="userSignedOut()" to="/login" class="default-btn">Sign in</router-link>
                  <router-link v-if="userSignedIn()" to="/login" class="default-btn signedIn-btn">Signed in</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Navbar Area -->

  </header>



</template>

<script>

export default{

  methods: {
    userSignedIn() {
      return this.$store.getters.userSignedIn
    },
    userSignedOut(){
      return (! this.userSignedIn())
    }

  }

}

</script>