<template>


  <div class="newsletter-wide">
    <div class="newsletter-wide-text">Subscribe to our newsletter to stay updated for sales, new products and other changes</div>

    <NewsletterInputSection/>

  </div>

</template>




<script>
import NewsletterInputSection from '@/views/fragments/NewsletterInputSection.vue'

export default {
  components: {
    NewsletterInputSection, // Local component registration
  }
}

</script>