<template>


  <div class="newsletter-wide">
    <div class="newsletter-wide-text">Please check your mailbox now and confirm your subscription!</div>



  </div>

</template>




<script>

</script>