<template>


  <div class="sale-banner" v-if="saleItem">
    <div class="sale-banner-text-row">
      <div class="discount-combo">
        <div class="sale-banner-text-discount-perc">{{displayedDiscount}}%</div>
        <div class="sale-banner-text-discount">discount</div>
      </div>

      <div class="sale-banner-text-desc">{{saleItem.title}}</div>
      <div class="discount-combo">
        <div class="sale-banner-text-coupon-text">use coupon:</div>
        <div class="sale-banner-text-coupon-code">{{saleItem.coupon}}</div>
      </div>
    </div>
  </div>

</template>




<script>
import axios from 'axios';

export default {
  data() {
    return {
      saleItem: null,
      displayedDiscount: 0, // Initial discount value for animation
    }
  },

  watch: {
    // Watcher on saleItem to start animation when it's loaded
    saleItem(newVal) {
      if (newVal && newVal.discount !== undefined) {
        this.animateDiscount();
      }
    }
  },

  methods: {
    animateDiscount() {
      let stepTime = 20; // Increase time between each increment to make it slower
      let totalSteps = 50; // Increase total steps for a smoother transition
      let incrementAmount = this.saleItem.discount / totalSteps;
      let currentStep = 0;

      const interval = setInterval(() => {
        currentStep++;
        this.displayedDiscount += incrementAmount;
        this.displayedDiscount = Math.round(this.displayedDiscount); // Round to nearest integer

        if (currentStep >= totalSteps) {
          clearInterval(interval); // Stop the interval when final value is reached
          this.displayedDiscount = this.saleItem.discount; // Ensure it ends exactly on the discount value
        }
      }, stepTime);
    }
  },

  created() {
    axios.get(process.env.VUE_APP_URL_GET_SUBJECTS + '/special/resource/sale', { cache: true })
        .then(res => {
          console.log("saleItem: ", res.data.payload.item);
          this.saleItem = res.data.payload.item;
        })
        .catch(error => console.log(error));
  },
}
</script>

