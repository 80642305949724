import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "about" */ '../views/FAQ.vue')
  },

  {
    path: '/terms',
    name: 'Terms',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Terms.vue')
  },

  {
    path: '/legal',
    name: 'Legal notice',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Legal.vue')
  },

  {
    path: '/privacy',
    name: 'Privacy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Privacy.vue')
  },
  {
    path: '/newslettersubscribed',
    name: 'NewsletterSubscribed',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsletterSubscribed.vue')
  },
  {
    path: '/ordercompleted',
    name: 'OrderCompleted',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OrderCompleted.vue')
  },
  {
    path: '/orders',
    name: 'Orders',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Orders.vue')
  },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/training/:sectionCat(.*)',
    name: 'Training',
    component: () => import(/* webpackChunkName: "training" */ '../views/Training.vue')
  },
  {
    path: '/training_session/:sessionId(.*)',
    name: 'TrainingSession',
    component: () => import(/* webpackChunkName: "training_session" */ '../views/TrainingSession.vue')
  },
  {
    path: '/training_create_session/:sectionCat(.*)',
    name: 'TrainingCreateSession',
    component: () => import(/* webpackChunkName: "training_create_session" */ '../views/TrainingCreateSession.vue')
  },
  {
    path: '/subject_details/:sectionCat(.*)',
    props: true,
    name: 'SubjectDetails',
    component: () => import(/* webpackChunkName: "product_details" */ '../views/SubjectDetails.vue')
  },
  {
    path: '/af/:afParams(.*)',
    props: true,
    name: 'Affiliate',
    component: () => import(/* webpackChunkName: "product_details" */ '../views/Affiliate/AffiliateRedirect.vue')
  },
  {
    path: '/af_link_gen',
    props: false,
    name: 'AffiliateLinkGenerator',
    component: () => import(/* webpackChunkName: "product_details" */ '../views/Affiliate/AffiliateLinkGenerator.vue')
  },
  {
    path: '/af_info',
    props: false,
    name: 'AffiliateInfo',
    component: () => import(/* webpackChunkName: "product_details" */ '../views/Affiliate/AffiliateInfo.vue')
  }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
