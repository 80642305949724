<template>

  <!-- Start Feature Area -->
  <section class="feature-area feature-area-three pb-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-sm-6">
          <div class="single-feature overly-one moveup1">
            <div class="overly-two">
              <div class="title">
                <i class='bx bxs-select-multiple'></i>
                <h3>Multiple choice</h3>
              </div>
              <p>Direct questions and direct answers. Select your answers and get explanations and related links directly to further material in the internet to learn with context.</p>
              <p>Detailed statistics help you to focus on your knowledge gaps and achieve success in the shortest possible time.</p>
              <div class="feature-shape">
                <img/>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6">
          <div class="single-feature overly-one moveup1">
            <div class="overly-two">
              <div class="title">
                <i class="fal fa-user-edit iconSize3"></i>
                <h3>Certification preparation</h3>
              </div>
              <p>If you want to prepare for CompTIA Linux+ or LPIC-1, you are right here! We take care you get in touch with the required topics and provide you realistic training questions, similar to the real test but without time pressure.</p>
              <div class="feature-shape">
                <img/>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
          <div class="single-feature overly-one moveup1">
            <div class="overly-two">
              <div class="title">
                <i class='bx bxl-tux iconSize4'></i>
                <h3>LINUX driven</h3>
              </div>
              <p>Made by experienced Linux administrators knowing what is required and what you need to know for an exam and real life.</p>
              <div class="feature-shape">
                <img/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Feature Area -->

</template>