<template>


    <NewsletterBanner v-if="showNewsletterBanner"/>

    <SaleBanner/>

  <!-- Pre-launch Mode Block -->
<!--  <div v-if="isProduction" class="prelaunch-mode">-->
<!--    Prelaunch mode - Do not use this page, except you are a beta tester!-->
<!--  </div>-->

  <!-- Start Banner Area -->
  <section class="banner-area banner-area-three">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">

          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="banner-content">
                <span class="top-title wow fadeInLeft" data-wow-delay="1s">First class</span>
                <h1 class="wow fadeInDown" data-wow-delay="0s">LINUX training</h1>

                <p class="wow fadeInUp" data-wow-delay="1s">
                  Exam preparation for <strong>CompTIA Linux+</strong> and <strong>LPIC-1</strong>. Master LINUX through exam-style practice at Bits & Bytes Academy.
                </p>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="banner-img wow fadeInDown" data-wow-delay="1s"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Banner Area -->

</template>

<script>
import NewsletterBanner from '@/views/content/splash/newsletterBanner.vue'
import SaleBanner from '@/views/content/splash/saleBanner.vue';

import { useCookies } from "vue3-cookies";

export default {
  components: {
    NewsletterBanner, // Local component registration
    SaleBanner
  },
  data() {
    return {
      isProduction: false,
      showNewsletterBanner: false,
    };
  },
  setup(){
    const { cookies } = useCookies();
    return { cookies };
  },
  created() {
    this.isProduction = window.location.hostname === 'bitsandbytes.academy';
    //this.isProduction = window.location.hostname === 'localhost';
  },

  mounted(){
    this.emitter.on("newsletterSubscribed", () => {
      console.log("event on: newsletterSubscribed");
      setTimeout(() => {
        console.log("newsletter banner off")
        this.showNewsletterBanner = false;

      }, 5000)
    });

    if (! this.cookies.isKey("newsletterSubscribed")){
      this.showNewsletterBanner = true;
    }
  }

}
</script>

<style>
.prelaunch-mode {
  width: 100%;
  height: 200px;
  background-color: #330000;
  color: white;
  text-align: center;
  line-height: 200px; /* Vertically center the text */
  font-size: 30px; /* Adjust as needed for 'big white letters' */
}
</style>