<script>
import jQuery from "jquery";
import WOW from 'wowjs';

export default {




  created () {


    function runWhenPageLoaded($){

      console.log("running extra scripts ...")

      'use strict';

      // start meanmenu (mobile)
      $.fn.meanmenu = function (options) {
        var defaults = {
          meanMenuTarget: jQuery(this), // Target the current HTML markup you wish to replace
          meanMenuContainer: '.mobile-nav', // Choose where meanmenu will be placed within the HTML
          meanMenuClose: "X", // single character you want to represent the close menu button
          meanMenuCloseSize: "18px", // set font size of close button
          meanMenuOpen: "<span /><span /><span />", // text/markup you want when menu is closed
          meanRevealPosition: "right", // left right or center positions
          meanRevealPositionDistance: "0", // Tweak the position of the menu
          meanRevealColour: "", // override CSS colours for the reveal background
          meanScreenWidth: "480", // set the screen width you want meanmenu to kick in at
          meanNavPush: "", // set a height here in px, em or % if you want to budge your layout now the navigation is missing.
          meanShowChildren: true, // true to show children in the menu, false to hide them
          meanExpandableChildren: true, // true to allow expand/collapse children
          meanExpand: "+", // single character you want to represent the expand for ULs
          meanContract: "-", // single character you want to represent the contract for ULs
          meanRemoveAttrs: false, // true to remove classes and IDs, false to keep them
          onePage: true, // set to true for one page sites
          meanDisplay: "block", // override display method for table cell based layouts e.g. table-cell
          removeElements: "" // set to hide page elements
        };
        options = $.extend(defaults, options);

        // get browser width
        var currentWidth = window.innerWidth || document.documentElement.clientWidth;

        return this.each(function () {
          var meanMenu = options.meanMenuTarget;
          var meanContainer = options.meanMenuContainer;
          var meanMenuClose = options.meanMenuClose;
          var meanMenuCloseSize = options.meanMenuCloseSize;
          var meanMenuOpen = options.meanMenuOpen;
          var meanRevealPosition = options.meanRevealPosition;
          var meanRevealPositionDistance = options.meanRevealPositionDistance;
          var meanRevealColour = options.meanRevealColour;
          var meanScreenWidth = options.meanScreenWidth;
          var meanNavPush = options.meanNavPush;
          var meanRevealClass = ".meanmenu-reveal";
          var meanShowChildren = options.meanShowChildren;
          var meanExpandableChildren = options.meanExpandableChildren;
          var meanExpand = options.meanExpand;
          var meanContract = options.meanContract;
          var meanRemoveAttrs = options.meanRemoveAttrs;
          var onePage = options.onePage;
          var meanDisplay = options.meanDisplay;
          var removeElements = options.removeElements;

          //detect known mobile/tablet usage
          var isMobile = false;
          if ( (navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i)) || (navigator.userAgent.match(/iPad/i)) || (navigator.userAgent.match(/Android/i)) || (navigator.userAgent.match(/Blackberry/i)) || (navigator.userAgent.match(/Windows Phone/i)) ) {
            isMobile = true;
          }

          if ( (navigator.userAgent.match(/MSIE 8/i)) || (navigator.userAgent.match(/MSIE 7/i)) ) {
            // add scrollbar for IE7 & 8 to stop breaking resize function on small content sites
            jQuery('html').css("overflow-y" , "scroll");
          }

          var meanRevealPos = "";
          var meanCentered = function() {
            if (meanRevealPosition === "center") {
              var newWidth = window.innerWidth || document.documentElement.clientWidth;
              var meanCenter = ( (newWidth/2)-22 )+"px";
              meanRevealPos = "left:" + meanCenter + ";right:auto;";

              if (!isMobile) {
                jQuery('.meanmenu-reveal').css("left",meanCenter);
              } else {
                jQuery('.meanmenu-reveal').animate({
                  left: meanCenter
                });
              }
            }
          };

          var menuOn = false;
          var meanMenuExist = false;


          if (meanRevealPosition === "right") {
            meanRevealPos = "right:" + meanRevealPositionDistance + ";left:auto;";
          }
          if (meanRevealPosition === "left") {
            meanRevealPos = "left:" + meanRevealPositionDistance + ";right:auto;";
          }
          // run center function
          meanCentered();

          // set all styles for mean-reveal
          var $navreveal = "";

          var meanInner = function() {
            // get last class name
            if (jQuery($navreveal).is(".meanmenu-reveal.meanclose")) {
              $navreveal.html(meanMenuClose);
            } else {
              $navreveal.html(meanMenuOpen);
            }
          };

          // re-instate original nav (and call this on window.width functions)
          var meanOriginal = function() {
            jQuery('.mean-bar,.mean-push').remove();
            jQuery(meanContainer).removeClass("mean-container");
            jQuery(meanMenu).css('display', meanDisplay);
            menuOn = false;
            meanMenuExist = false;
            jQuery(removeElements).removeClass('mean-remove');
          };

          // navigation reveal
          var showMeanMenu = function() {
            var meanStyles = "background:"+meanRevealColour+";color:"+meanRevealColour+";"+meanRevealPos;
            if (currentWidth <= meanScreenWidth) {
              jQuery(removeElements).addClass('mean-remove');
              meanMenuExist = true;
              // add class to body so we don't need to worry about media queries here, all CSS is wrapped in '.mean-container'
              jQuery(meanContainer).addClass("mean-container");
              jQuery('.mean-container').prepend('<div class="mean-bar"><a href="#nav" class="meanmenu-reveal" style="'+meanStyles+'">Show Navigation</a><nav class="mean-nav"></nav></div>');

              //push meanMenu navigation into .mean-nav
              var meanMenuContents = jQuery(meanMenu).html();
              jQuery('.mean-nav').html(meanMenuContents);

              // remove all classes from EVERYTHING inside meanmenu nav
              if(meanRemoveAttrs) {
                jQuery('nav.mean-nav ul, nav.mean-nav ul *').each(function() {
                  // First check if this has mean-remove class
                  if (jQuery(this).is('.mean-remove')) {
                    jQuery(this).attr('class', 'mean-remove');
                  } else {
                    jQuery(this).removeAttr("class");
                  }
                  jQuery(this).removeAttr("id");
                });
              }

              // push in a holder div (this can be used if removal of nav is causing layout issues)
              jQuery(meanMenu).before('<div class="mean-push" />');
              jQuery('.mean-push').css("margin-top",meanNavPush);

              // hide current navigation and reveal mean nav link
              jQuery(meanMenu).hide();
              jQuery(".meanmenu-reveal").show();

              // turn 'X' on or off
              jQuery(meanRevealClass).html(meanMenuOpen);
              $navreveal = jQuery(meanRevealClass);

              //hide mean-nav ul
              jQuery('.mean-nav ul').hide();

              // hide sub nav
              if(meanShowChildren) {
                // allow expandable sub nav(s)
                if(meanExpandableChildren){
                  jQuery('.mean-nav ul ul').each(function() {
                    if(jQuery(this).children().length){
                      jQuery(this,'li:first').parent().append('<a class="mean-expand" href="#" style="font-size: '+ meanMenuCloseSize +'">'+ meanExpand +'</a>');
                    }
                  });
                  jQuery('.mean-expand').on("click",function(e){
                    e.preventDefault();
                    if (jQuery(this).hasClass("mean-clicked")) {
                      jQuery(this).text(meanExpand);
                      jQuery(this).prev('ul').slideUp(300, function(){});
                    } else {
                      jQuery(this).text(meanContract);
                      jQuery(this).prev('ul').slideDown(300, function(){});
                    }
                    jQuery(this).toggleClass("mean-clicked");
                  });
                } else {
                  jQuery('.mean-nav ul ul').show();
                }
              } else {
                jQuery('.mean-nav ul ul').hide();
              }

              // add last class to tidy up borders
              jQuery('.mean-nav ul li').last().addClass('mean-last');
              $navreveal.removeClass("meanclose");
              jQuery($navreveal).click(function(e){
                e.preventDefault();
                if( menuOn === false ) {
                  $navreveal.css("text-align", "center");
                  $navreveal.css("text-indent", "0");
                  $navreveal.css("font-size", meanMenuCloseSize);
                  jQuery('.mean-nav ul:first').slideDown();
                  menuOn = true;
                } else {
                  jQuery('.mean-nav ul:first').slideUp();
                  menuOn = false;
                }
                $navreveal.toggleClass("meanclose");
                meanInner();
                jQuery(removeElements).addClass('mean-remove');
              });

              // for one page websites, reset all variables...
              if ( onePage ) {
                jQuery('.mean-nav ul > li > a:first-child').on( "click" , function () {
                  jQuery('.mean-nav ul:first').slideUp();
                  menuOn = false;
                  jQuery($navreveal).toggleClass("meanclose").html(meanMenuOpen);
                });
              }
            } else {
              meanOriginal();
            }
          };

          if (!isMobile) {
            // reset menu on resize above meanScreenWidth
            jQuery(window).resize(function () {
              currentWidth = window.innerWidth || document.documentElement.clientWidth;
              if (currentWidth > meanScreenWidth) {
                meanOriginal();
              } else {
                meanOriginal();
              }
              if (currentWidth <= meanScreenWidth) {
                showMeanMenu();
                meanCentered();
              } else {
                meanOriginal();
              }
            });
          }

          jQuery(window).resize(function () {
            // get browser width
            currentWidth = window.innerWidth || document.documentElement.clientWidth;

            if (!isMobile) {
              meanOriginal();
              if (currentWidth <= meanScreenWidth) {
                showMeanMenu();
                meanCentered();
              }
            } else {
              meanCentered();
              if (currentWidth <= meanScreenWidth) {
                if (meanMenuExist === false) {
                  showMeanMenu();
                }
              } else {
                meanOriginal();
              }
            }
          });

          // run main menuMenu function on load
          showMeanMenu();
        });
      };
      // end meanmenu (mobile)


      // Mean Menu JS
      $('.mean-menu').meanmenu({
        meanScreenWidth: "991"
      });
      //
      // // Nice Select JS
      // $('select').niceSelect();
      //
      // Header Sticky, Go To Top JS JS
      $(window).on('scroll', function() {

        // Header Sticky JS
        if ($(this).scrollTop() >150){
          $('.navbar-area').addClass("is-sticky");
        }
        else{
          $('.navbar-area').removeClass("is-sticky");
        }

        // Go To Top JS
        var scrolled = $(window).scrollTop();
        if (scrolled > 300) $('.go-top').addClass('active');
        if (scrolled < 300) $('.go-top').removeClass('active');

      });
      //
      // // Testimonials Slider JS
      // $('.testimonials-slider').owlCarousel({
      //   items: 1,
      //   loop: true,
      //   margin: 0,
      //   nav: true,
      //   dots: false,
      //   autoplay: true,
      //   smartSpeed: 1000,
      //   autoplayHoverPause: true,
      //   navText: [
      //     "<i class='flaticon-left-arrow'></i>",
      //     "<i class='flaticon-right-arrow'></i>",
      //   ],
      // });
      //
      // // Partner Slider JS
      // $('.partner-slider').owlCarousel({
      //   loop: true,
      //   margin: 30,
      //   nav: false,
      //   dots: false,
      //   autoplay: true,
      //   smartSpeed: 1000,
      //   autoplayHoverPause: true,
      //   navText: [
      //     "<i class='bx bx-chevron-left'></i>",
      //     "<i class='bx bx-chevron-right'></i>",
      //   ],
      //   responsive:{
      //     0:{
      //       items: 2,
      //     },
      //     576:{
      //       items: 3,
      //     },
      //     768:{
      //       items: 4,
      //     },
      //     1200:{
      //       items: 5,
      //     }
      //   }
      // });
      //
      // // Related Product JS
      // $('.related-product').owlCarousel({
      //   loop: true,
      //   margin: 30,
      //   nav: false,
      //   dots: false,
      //   autoplay: true,
      //   smartSpeed: 1000,
      //   autoplayHoverPause: true,
      //   responsive:{
      //     0:{
      //       items: 1,
      //     },
      //     576:{
      //       items: 2,
      //     },
      //     768:{
      //       items: 3,
      //     },
      //     1200:{
      //       items: 3,
      //     }
      //   }
      // });
      //
      // // Click Event JS
      // $('.go-top').on('click', function() {
      //   $("html, body").animate({ scrollTop: "0" },  500);
      // });
      //
      // // // FAQ Accordion JS
      // // $('.accordion').find('.accordion-title').css( "background-color", "red" );
      // // $('.accordion').find('.accordion-title').on('click', function(){
      // //   // Adds Active Class
      // //   $(this).toggleClass('active');
      // //   // Expand or Collapse This Panel
      // //   $(this).next().slideToggle('fast');
      // //   // Hide The Other Panels
      // //   $('.accordion-content').not($(this).next()).slideUp('fast');
      // //   // Removes Active Class From Other Titles
      // //   $('.accordion-title').not($(this)).removeClass('active');
      // //
      // // });
      //
      // // Count Time JS
      // function makeTimer() {
      //   //var endTime = new Date("november  30, 2021 17:00:00 PDT");
      //   var endTime = (Date.parse(endTime)) / 1000;
      //   //var now = new Date();
      //   var now = (Date.parse(now) / 1000);
      //   var timeLeft = endTime - now;
      //   var days = Math.floor(timeLeft / 86400);
      //   var hours = Math.floor((timeLeft - (days * 86400)) / 3600);
      //   var minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600 )) / 60);
      //   var seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));
      //   if (hours < "10") { hours = "0" + hours; }
      //   if (minutes < "10") { minutes = "0" + minutes; }
      //   if (seconds < "10") { seconds = "0" + seconds; }
      //   $("#days").html(days + "<span>Days</span>");
      //   $("#hours").html(hours + "<span>Hours</span>");
      //   $("#minutes").html(minutes + "<span>Minutes</span>");
      //   $("#seconds").html(seconds + "<span>Seconds</span>");
      // }
      // setInterval(function() { makeTimer(); }, 300);
      //
      // Tabs JS
      // $('.tab ul.tabs').addClass('active').find('> li:eq(0)').addClass('current');
      // $('.tab ul.tabs li').on('click', function (g) {
      //   var tab = $(this).closest('.tab'),
      //       index = $(this).closest('li').index();
      //   tab.find('ul.tabs > li').removeClass('current');
      //   $(this).closest('li').addClass('current');
      //   tab.find('.tab_content').find('div.tabs_item').not('div.tabs_item:eq(' + index + ')').slideUp();
      //   tab.find('.tab_content').find('div.tabs_item:eq(' + index + ')').slideDown();
      //   g.preventDefault();
      // });
      //
      // // Preloader
      // $(window).on('load', function() {
      //   $('.preloader').addClass('preloader-deactivate');
      // })
      //
      // // Subscribe form JS
      // $(".newsletter-form").validator().on("submit", function (event) {
      //   if (event.isDefaultPrevented()) {
      //     // handle the invalid form...
      //     formErrorSub();
      //     submitMSGSub(false, "Please enter your email correctly.");
      //   } else {
      //     // everything looks good!
      //     event.preventDefault();
      //   }
      // });
      // function callbackFunction (resp) {
      //   if (resp.result === "success") {
      //     formSuccessSub();
      //   }
      //   else {
      //     formErrorSub();
      //   }
      // }
      // function formSuccessSub(){
      //   $(".newsletter-form")[0].reset();
      //   submitMSGSub(true, "Thank you for subscribing!");
      //   setTimeout(function() {
      //     $("#validator-newsletter").addClass('hide');
      //   }, 4000)
      // }
      // function formErrorSub(){
      //   $(".newsletter-form").addClass("animated shake");
      //   setTimeout(function() {
      //     $(".newsletter-form").removeClass("animated shake");
      //   }, 1000)
      // }
      // function submitMSGSub(valid, msg){
      //   var msgClasses = ""
      //   if(valid){
      //     msgClasses = "validation-success";
      //   } else {
      //     msgClasses = "validation-danger";
      //   }
      //   $("#validator-newsletter, #validator-newsletter-2").removeClass().addClass(msgClasses).text(msg);
      // }
      //
      // // AJAX MailChimp JS
      // $(".newsletter-form").ajaxChimp({
      //   url: "https://Envy Theme.us20.list-manage.com/subscribe/post?u=60e1ffe2e8a68ce1204cd39a5&amp;id=42d6d188d9", // Your url MailChimp
      //   callback: callbackFunction
      // });
      //
      // // Input Plus & Minus Number JS
      // $('.input-counter').each(function() {
      //   var spinner = jQuery(this),
      //       input = spinner.find('input[type="text"]'),
      //       btnUp = spinner.find('.plus-btn'),
      //       btnDown = spinner.find('.minus-btn'),
      //       min = input.attr('min'),
      //       max = input.attr('max');
      //
      //   btnUp.on('click', function() {
      //     var oldValue = parseFloat(input.val());
      //     if (oldValue >= max) {
      //       var newVal = oldValue;
      //     } else {
      //       newVal = oldValue + 1;
      //     }
      //     spinner.find("input").val(newVal);
      //     spinner.find("input").trigger("change");
      //   });
      //   btnDown.on('click', function() {
      //     var oldValue = parseFloat(input.val());
      //     if (oldValue <= min) {
      //       var newVal = oldValue;
      //     } else {
      //       newVal = oldValue - 1;
      //     }
      //     spinner.find("input").val(newVal);
      //     spinner.find("input").trigger("change");
      //   });
      // });
      //
      // // Odometer JS
      // $('.odometer').appear(function() {
      //   var odo = $(".odometer");
      //   odo.each(function() {
      //     var countNumber = $(this).attr("data-count");
      //     $(this).html(countNumber);
      //   });
      // });
      //

      // Others Option For Responsive JS - ... menu for mobile
      $(".others-option-for-responsive .dot-menu").on("click", function(){
        $(".others-option-for-responsive .container .container").toggleClass("active");
      });


      // Disabled for now as it displays it anyway at the beginning - look strange
      if (WOW && $){
        console.log("WOW and jQuery enabled")
      }

      // // WOW JS
      // console.log("WOW elements: "+$('.wow').length)
      // if($('.wow').length > 0){
      //   var wow = new WOW.WOW({
      //     mobile: false
      //   });
      //
      //   wow.init();
      // }

      // // Popup Video JS
      // $('.popup-youtube, .popup-vimeo').magnificPopup({
      //   disableOn: 300,
      //   type: 'iframe',
      //   mainClass: 'mfp-fade',
      //   removalDelay: 160,
      //   preloader: false,
      //   fixedContentPos: false,
      // });
      //
      // // Product View Slider JS
      // var bigimage = $("#big");
      // var thumbs = $("#thumbs");
      // // Var Totalslides = 10;
      // var syncedSecondary = true;
      //
      // bigimage
      //     .owlCarousel({
      //       items: 1,
      //       slideSpeed: 2000,
      //       nav: true,
      //       autoplay: true,
      //       dots: false,
      //       loop: true,
      //       responsiveRefreshRate: 200,
      //       navText: [
      //         "<i class='bx bx-left-arrow-alt'></i>",
      //         "<i class='bx bx-right-arrow-alt'></i>",
      //       ]
      //     })
      //     .on("changed.owl.carousel", syncPosition);
      //
      // thumbs
      //     .on("initialized.owl.carousel", function() {
      //       thumbs
      //           .find(".owl-item")
      //           .eq(0)
      //           .addClass("current");
      //     })
      //     .owlCarousel({
      //       items: 5,
      //       dots: false,
      //       nav: false,
      //       navText: [
      //         "<i class='bx bx-left-arrow-alt'></i>",
      //         "<i class='bx bx-right-arrow-alt'></i>",
      //       ],
      //       smartSpeed: 200,
      //       slideSpeed: 500,
      //       slideBy: 4,
      //       responsiveRefreshRate: 100
      //     })
      //     .on("changed.owl.carousel", syncPosition2);
      //
      // function syncPosition(el) {
      //   //if loop is set to false, then you have to uncomment the next line
      //   //var current = el.item.index;
      //
      //   //to disable loop, comment this block
      //   var count = el.item.count - 1;
      //   var current = Math.round(el.item.index - el.item.count / 2 - 0.5);
      //
      //   if (current < 0) {
      //     current = count;
      //   }
      //   if (current > count) {
      //     current = 0;
      //   }
      //
      //   //to this
      //   thumbs
      //       .find(".owl-item")
      //       .removeClass("current")
      //       .eq(current)
      //       .addClass("current");
      //   var onscreen = thumbs.find(".owl-item.active").length - 1;
      //   var start = thumbs
      //       .find(".owl-item.active")
      //       .first()
      //       .index();
      //   var end = thumbs
      //       .find(".owl-item.active")
      //       .last()
      //       .index();
      //
      //   if (current > end) {
      //     thumbs.data("owl.carousel").to(current, 100, true);
      //   }
      //   if (current < start) {
      //     thumbs.data("owl.carousel").to(current - onscreen, 100, true);
      //   }
      // }
      // function syncPosition2(el) {
      //   if (syncedSecondary) {
      //     var number = el.item.index;
      //     bigimage.data("owl.carousel").to(number, 100, true);
      //   }
      // }
      // thumbs.on("click", ".owl-item", function(e) {
      //   e.preventDefault();
      //   var number = $(this).index();
      //   bigimage.data("owl.carousel").to(number, 300, true);
      // });

    }



    // for jQuery it must run very classical when window is loaded otherwise the DOM is not ready (also not with mounted)
    window.addEventListener('load', function () {
      console.log("Window loaded")
      runWhenPageLoaded(jQuery)
    })

  },

}

</script>