<template>

  <!-- Start Feature Area -->
  <!-- Start Feature Area -->
  <section class="feature-area feature-area-six pb-70">
    <div class="container">

      <PulseLoaderBar v-if="subjects.length === 0"/>

      <!-- new style -->
      <div class="row">

        <div class="col-lg-6 col-sm-6" v-for="subject in subjects" v-bind:key="subject.sectionCat">
          <div class="boxshadow1 moveup1 availableTrainings">

            <div class="title showpointer"  v-on:click="goToDetailsPageFor(subject.sectionCat)" >
              <i v-bind:class="subject.symbol" class="flaticon-testing"></i> <!-- you can use DB for icon -->
              <h3>{{ subject.title }}</h3>
            </div>
            <div class="trainingDesc showpointer"  v-on:click="goToDetailsPageFor(subject.sectionCat)">
              <div class="trainingDescTextblock">
                <p>{{ subject.desc }}</p>
                <p>More than {{subject.minAmountOfQuestions}} questions.</p>
                <p class="priceText1" v-if="subject.price === 0">for FREE !</p>
                <p class="priceText1" v-else>from {{subject.priceFrom}} €/$ /mo</p>
              </div>
              <div class="trainingDescImageblock" :class="subject.sectionCat.replace('/','_')"><img/></div>
            </div>

            <div class="trainingsButtonSection2">
              <router-link :to="linkSectionCat(subject.sectionCat)" class="default-btn">
                details
              </router-link>
              <UserOpenSectionCat :subject="subject"/>
            </div>


          </div>



        </div>

      </div>



    </div>
  </section>
  <!-- End Feature Area -->



</template>




<script>
import axios from 'axios';
import UserOpenSectionCat from '@/views/fragments/UserOpenSectionCat.vue'
import PulseLoaderBar from '@/views/fragments/PulseLoaderBar.vue'

export default {
  components: {
    UserOpenSectionCat,
    PulseLoaderBar
  },

  data() {
    return {
      subjects: [
      ]
    }
  },

  created() {
    axios.get(process.env.VUE_APP_URL_GET_SUBJECTS + '/', { cache: true }).then(
        res=> {
          this.subjects=res.data.payload.records
        }
    ).catch(error => console.log(error))
  },

  methods:{
    linkSectionCat(sectionCat){
      return '/subject_details/'+sectionCat
    },

    goToDetailsPageFor(sectionCat){
      this.$router.push({ path: this.linkSectionCat(sectionCat) })
    }
  },



}

</script>
