<template>

  <router-link v-if="mayUserOpenIt" :to="linkTraining(this.subject.sectionCat)" class="default-btn altbgcolor2">
    open training
  </router-link>

<!--  <router-link v-if="! mayUserOpenIt" :to="linkTraining(this.subject.sectionCat)" class="default-btn">-->
<!--    buy-->
<!--  </router-link>-->

  <!--  <div v-if="! userSignedIn">-->
<!--    <div v-if="this.subject.priceFrom === 0">-->
<!--      free | <router-link to="/login">login</router-link>-->
<!--    </div>-->
<!--    <div v-else>-->
<!--      buy | <router-link to="/login">login</router-link>-->
<!--    </div>-->
<!--  </div>-->




</template>



<script>
import { Auth } from 'aws-amplify';
import { AuthState  } from '@aws-amplify/ui-components'
import axios from "axios";
import  { initAuthUser, changeOnAuthEvent } from '@/js/authutil'
import {
  extractCachetimeFromHeaders,
} from '@/js/cacheutil'

export default {
  props: ['subject'],

  data() {
    return {
      user_owns_sectioncat: false,
      initProcessDone: false
    }
  },

  created() {
    // excluded functions
    initAuthUser(this, Auth, AuthState, this.askServerIfUserOwnsSectionCat)
    changeOnAuthEvent(this, this.onAuthComponentProcessed) // INIT process on change (late login)

    // if no auth change happened, trigger ask for owned sectioncats
    if (this.userSignedIn){
      this.askServerIfUserOwnsSectionCat()
    }
  },

  updated() {

  },

  computed: {
    userSignedIn(){
      return this.$store.getters.userSignedIn
    },
    mayUserOpenIt(){
      if (this.userSignedIn && (this.user_owns_sectioncat || this.subject.priceFrom === 0)){
        return true
      }else{
        return false
      }
    }
  },

  methods:{

    updateUserOwnsSectionCatReturn() {
      this.$emit('update-user-owns-sectioncat', this.user_owns_sectioncat); // Emit event with the updated value
    },

    onAuthComponentProcessed: function(){
      console.log("UserOpenSectionCat - onAuthComponentProcessed() ...")
      this.askServerIfUserOwnsSectionCat()
    },

    // loads the content or redirectsif user is not signedin.
    // Must be done AFTER amplify-authenticator has been loaded
    // and updated to know if user is signedin or not.
    askServerIfUserOwnsSectionCat: function() {
      // if (this.initProcessDone === true){return} // skip if already done
      if (this.subject.priceFrom === 0){
        console.log("Skipping for %s as it is free", this.subject.sectionCat)
        return
      }


      console.log('onUserSignedIn() authState: ' + this.authState)

      this.sectionCat = this.subject.sectionCat
      console.log("UserOpenSectionCat, sectionCat: %s", this.sectionCat)

      // per default user does not own section-cat
      this.user_owns_sectioncat = false
      this.updateUserOwnsSectionCatReturn();


      // ask server if user owns sectioncat - only if user is signedin
      if (this.authState && this.sectionCat !== undefined){ // there is a moment, when it is still undefined
        const url = process.env.VUE_APP_URL_GET_USROWNSSECTIONCAT+'/'+this.sectionCat

        console.log("user is signedin, auth state: %s. Going to ask server if user owns %s", this.authState, this.sectionCat )

        axios.defaults.headers = {
          'Content-Type': 'application/json',
          'Authorization': this.$store.getters.userAccessToken,
          'Authentication': this.$store.getters.userIdToken, // because it holds the eMail address. The "Authentication" field is nothing API gateway understands, it is an own definition
        }

        console.log("GET: "+url)
        axios.get(url, { cache: true }).then(
            res=> {
              if (res.data.payload['userOwnsProduct']){
                this.user_owns_sectioncat = true;
              }else{
                this.user_owns_sectioncat = false;
              }
              this.updateUserOwnsSectionCatReturn();
              console.log('does user own sectioncat %s ? = %s', this.sectionCat, this.user_owns_sectioncat)
              console.log("axios header cache-control: ", extractCachetimeFromHeaders(res.headers))

              // this.initProcessDone = true
            }
        ).catch(error => console.log(error));


      }else{
        console.log("user is NOT signedin or no sectioncat, auth state: %s. Going NOT to ask server if user owns %s", this.authState, this.sectionCat )
        // this.initProcessDone = false
      }
    },

    linkTraining(sectionCat){
      return '/training/'+sectionCat
    },

  }

};



</script>