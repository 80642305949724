<template>


  <section class="homebigadvimage">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="banner-content">
                <span class="top-title wow fadeInDown" data-wow-delay="1s">Optimized learning strategy</span>
                <h1 class="wow fadeInDown" data-wow-delay="1s">Strengths analysis</h1>

                <p class="wow fadeInUp" data-wow-delay="1s">
                  Bits & Bytes assists you in identifying your strengths and areas for improvement.
                </p>
                <p class="wow fadeInUp" data-wow-delay="1s">
                  Concentrate on what you need to learn and don't waste your time repeating questions you already know.
                </p>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="banner-img wow fadeInDown" data-wow-delay="1s"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


</template>

<scripts>

</scripts>