<template>
  <!-- Start Page Title Area -->
  <div class="page-title-area">
    <div class="container">
      <div class="page-title-content">
        <h2>Sign in</h2>
        <ul>
          <li>
            <router-link to="/" class="nav-link">
              <i class="bx bx-home"></i>
              Home
            </router-link>
          </li>
          <li class="active">sign in</li>
        </ul>
      </div>
    </div>
  </div>
  <!-- End Page Title Area -->




  <!-- Start User Area -->
  <section class="user-area-style ptb-70">

    <div class="container termshint">
    <p>
      If you signup or signin, you agree to our
      <router-link to="/terms">Terms & conditions</router-link> and
      <router-link to="/privacy">Privacy policy</router-link>
    </p>
    </div>


    <amplify-authenticator id="amplify-authenticator">

      <!-- https://docs.amplify.aws/ui/auth/authenticator/q/framework/vue/#breaking-changes-for-withauthenticator -->

      <amplify-sign-up
          slot="sign-up"
          username-alias="email"
          :formFields="formFieldsSignup"
      ></amplify-sign-up>

      <amplify-sign-in
          header-text="Sign in"
          slot="sign-in"
          :formFields="formFieldsSignin"
      ></amplify-sign-in>

      <amplify-forgot-password
          header-text="Reset your password"
          slot="forgot-password"
          :formFields="formFieldsResetPassword"
      ></amplify-forgot-password>

      <!--    <amplify-sign-out></amplify-sign-out>-->

    </amplify-authenticator>







    <div class="container" v-if="$store.getters.userSignedIn">
      <div class="contact-form-action">
        <div class="account-title">
          <h2>Account</h2>
        </div>

        <form action="javascript:void(0);">
          <div class="row">

            <div class="col-12">
              <div class="form-group">
                <label>Email</label>
                <input class="form-control" type="text" name="name" :value="$store.getters.userName" readonly>
              </div>
            </div>

            <div class="col-12">
              <div class="form-group">
                <label>User-ID</label>
                <input class="form-control" type="text" name="name" :value="$store.getters.userId" readonly>
              </div>
            </div>

            <div class="col-12">
              <div class="link-block">
                <label>Orders</label>
                <div class="form-control"><router-link to="/orders">show order history</router-link></div>
              </div>
            </div>


            <div class="col-12">
              <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6">
                  <button v-on:click="signOut()" class="default-btn">signout</button>
                </div>


              </div>
            </div>

          </div>
        </form>
      </div>

    </div>
  </section>
  <!-- End User Area -->




<!--  <h1>{{ $store.getters.userSignedIn }} | {{ $store.getters.userName }} | {{ $store.getters.userId }} </h1>-->



<!--  <div>-->
<!--    <button v-on:click="signOut()" class="default-btn">signout</button>-->
<!--    <button v-on:click="refreshToken()" class="default-btn">refresh</button>-->
<!--  </div>-->


</template>



<script>

import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import { Hub, Auth  } from 'aws-amplify';

export default {
  created() {

    this.refreshTokenTimer = setInterval(this.refreshToken, 10000000); // 2,7h - timeout is 3h

    Hub.listen('auth', this.authListener);

    // is user already signed in?
    this.updateUserTokens()

    // update login-state - always required - but it is not triggered on start if user already signedin
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      if (this.$store.state != undefined){
        this.$store.commit("updateAuthState", {authState,authData});
        this.updateUserTokens()
      }
    });

    window.scrollTo(0,0); // scroll to top

  },
  methods: {

    updateUserTokens: function(){
      this.$store.commit("updateUserTokens");
    },

    signOut: function () {
      try {
        Auth.signOut();
        this.$store.commit("removeUserTokens");
      } catch (error) {
        console.log('error signing out: ', error);
      }
    },

    refreshToken: function() {
      console.log('refreshToken timer event')
        Auth.currentAuthenticatedUser().then(cognitoUser => {
          if (cognitoUser != undefined){
            console.log(cognitoUser)
            const currentSession = cognitoUser.signInUserSession;

            try {
              cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
                console.log(err)
                console.log(session)
              })
            }catch(e){console.log(e)}
          }
        })



    },


      authListener: function(data) {

      this.updateUserTokens()

      switch (data.payload.event) {
        case 'sign':
          console.log('user signed in');
          break;
        case 'signUp':
          console.log('user signed up');
          break;
        case 'signOut':
          console.log('user signed out');
          break;
        case 'sign_failure':
          console.log('user sign in failed');
          break;
        case 'tokenRefresh':
          console.log('token refresh succeeded');
          break;
        case 'tokenRefresh_failure':
          console.log('token refresh failed');
          break;
        case 'configured':
          console.log('the Auth module is configured');
      }
    }


  },


  data() {
    return {
      formFieldsSignup: [
        {
          type: 'email',
          label: 'Email',
          placeholder: '',
          inputProps: { required: true, autocomplete: 'username' },
        },
        {
          type: 'password',
          label: 'Password',
          placeholder: '',
          inputProps: { required: true, autocomplete: 'new-password' },
        }
      ],

      formFieldsSignin: [
        {
          type: 'username',
          label: 'Email address',
          placeholder: '',
          inputProps: { required: true, autocomplete: 'username' },
        },
        {
          type: 'password',
          label: 'Password',
          placeholder: '',
          inputProps: { required: true, autocomplete: 'new-password' },
        }
      ],

      formFieldsResetPassword: [
        {
          type: 'username',
          label: 'Email address',
          placeholder: '',
          inputProps: { required: true, autocomplete: 'username' },
        },
      ]

    }
  }
}

</script>