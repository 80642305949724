<template>
  <div class="newsletter-wide-input-section">

    <div class="newsletter-wide-email">
      <input class="form-control" type="email" name="email" placeholder="Enter an email address" v-model="newsletterEmail">
    </div>
    <div class="newsletter-button">
      <button class="subscribe-btn" :disabled="!newsletterEmailValid" v-on:click="subscribe">Subscribe</button>
    </div>

  </div>

</template>



<script>
import { useCookies } from "vue3-cookies";

export default {
  // props: ['product', 'sectionCatItem'],

  setup(){
    const { cookies } = useCookies();
    return { cookies };
  },

  data() {
    return {
      newsletterEmail: "",
      newsletterEmailValid: false
    }
  },

  watch: {
    newsletterEmail(newEmail) {
      // Remove all whitespaces from the email address
      const trimmedEmail = newEmail.replace(/\s+/g, '');
      this.newsletterEmail = trimmedEmail;

      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.newsletterEmailValid = emailRegex.test(trimmedEmail);

      if (this.newsletterEmailValid){
        this.updateMlEmailFieldValue(this.newsletterEmail);
      }
    }
  },

  mounted() {
    setTimeout(() => {
      if (this.$store.getters.userSignedIn){
        this.newsletterEmail = this.$store.getters.userName;
      }
    }, "3000");

  },


  methods: {
    subscribe(){
      console.log("subscribe to newsletter");
      this.emitter.emit("newsletterSubscribed", {} );

      // setting cookie
      this.cookies.set("newsletterSubscribed", new Date().toUTCString());

      this.clickMlSubscribeButton();
    },


    // the code below uses the hidden mailerlite form as relay:

    // update mailerlite form
    updateMlEmailFieldValue(email) {
      // Find the div with the id "mailerliteForm"
      const mailerLiteForm = document.getElementById('mailerliteForm');
      if (!mailerLiteForm) {
        console.error('The mailerliteForm div could not be found.');
        return;
      }

      // Within the found div, find the input field of type "email"
      const emailInputField = mailerLiteForm.querySelector('input[type="email"]');
      if (!emailInputField) {
        console.error('The email input field could not be found.');
        return;
      }

      // Set the provided email as the new value of the input field
      emailInputField.value = email;
    },

    // click ob the mailerlite submit button
    clickMlSubscribeButton() {
      // Find the div with the id 'mailerliteForm'
      const mailerLiteForm = document.getElementById('mailerliteForm');

      // Check if the div exists
      if (mailerLiteForm) {
        // Find the submit button inside the div
        const submitButton = mailerLiteForm.querySelector('button[type="submit"]');

        // Check if the button exists and trigger a click
        if (submitButton) {
          submitButton.click();
        } else {
          console.error('Submit button not found inside #mailerliteForm');
        }
      } else {
        console.error('#mailerliteForm not found');
      }
    }
  }

};




</script>
