<template>


  <!-- Start Footer Area -->
  <footer class="footer-area pt-100 pb-70 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div class="single-footer-widget">
            <a href="index.html" class="logo-footer">
              <img alt="bitsandbytes">
            </a>

            <!-- <p>Follow at linkedin to stay updated for new content.</p> -->

            <!-- <ul class="social-icon">
              <li>
                <a href="#">
                  <i class="bx bxl-linkedin-square"></i>
                </a>
              </li>
            </ul> -->
          </div>
        </div>



        <div class="col-lg-3 col-md-6">
          <div class="single-footer-widget">
            <h3>Resources</h3>

            <ul class="import-link">
              <li>
                <router-link to="/faq">FAQ</router-link>
              </li>
              <li>
                <router-link to="/login">Login</router-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="single-footer-widget">
            <h3>Legal</h3>

            <ul class="import-link">
              <li>
                <router-link to="/terms">Terms & conditions</router-link>
              </li>
              <li>
                <router-link to="/privacy">Privacy policy</router-link>
              </li>
              <li>
                <router-link to="/legal">Legal notice</router-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="single-footer-widget">
            <h3>Affiliate</h3>

            <ul class="import-link">
              <li>
                <router-link to="/af_info">Earn money</router-link>
              </li>
              <li>
                <router-link to="/af_link_gen">Link generator</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- End Footer Area -->


  <!-- Start Go Top Area -->
<!--  <div class="go-top">-->
<!--  <i class="bx bx-chevrons-up"></i>-->
<!--  <i class="bx bx-chevrons-up"></i>-->
<!--  </div>-->

</template>


<script setup>
</script>